<template>
    <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
    >
        <b-row>
            <b-col cols="12" class="p-0">
                <b-row class="m-0 w-100">
                    <b-col cols="12" class=" mt-2 mb-2">
                        <router-link :to="{ name: 'sheet_equipment', params: { id: this.$route.params.id } }"
                                     class="mr-1">
                            <b-button class="btn btn-secondary mb-1 mb-sm-0" type="button">
                                Ver ficha Equipo
                            </b-button>
                        </router-link>
                    </b-col>
                </b-row>

                <b-row class="m-0 w-100">
                    <b-col v-for="intervention in interventions" :key="intervention.id" cols="12" sm="6" lg="4">
                        <b-link :to="{ name: 'sheet_intervention', params: { id: intervention.id } }">
                            <b-card no-body>
                                <div class="item-img text-center">
                                    <b-img alt="asdf" fluid class="card-img-top"
                                           :src="baseUrl + (intervention.intervention_photo ? 'storage/' + intervention.intervention_photo : 'images/placeholder.webp')"
                                           style="height: 200px; object-fit: cover; object-position: center;"/>
                                </div>
                                <b-card-body>
                                    <table>
                                        <tr>
                                            <td class="text-muted pr-1">Registro:</td>
                                            <td class="text-dark">{{ intervention.id }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted pr-1">Estado:</td>
                                            <td class="text-dark">{{ intervention.state.name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="text-muted pr-1">Descipción:</td>
                                            <td class="text-dark"
                                                style="overflow: hidden;">
                                                {{ intervention.description }}
                                            </td>
                                        </tr>
                                    </table>
                                </b-card-body>

                      
                                <div class="item-options text-center">
                                    <div :class="intervention.state.color"
                                         class="text-white py-1 w-100 rounded-bottom">
                                        <feather-icon icon="ArchiveIcon" class="mr-50"/>
                                        <span>Ver intervención</span>
                                    </div>
                                </div>
                            </b-card>
                        </b-link>
                    </b-col>
                </b-row>
            </b-col>

            <b-col cols="12" class="p-0">
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6"
                               class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">
                            Mostrando {{ pageLength }} de {{ totalRecords }} Intervenciones
                        </span>
                        </b-col>

                        <b-col cols="12" sm="6"
                               class="d-flex align-items-center justify-content-center justify-content-sm-end">
                            <b-pagination :value="1" :total-rows="totalRecords" :per-page="pageLength" first-number
                                          last-number
                                          align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                                          @input="changePage">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18"/>
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18"/>
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>
    </b-overlay>
</template>

<script>
import {BRow, BCol, BCard, BPagination, BLink, BCardBody, BCardText, BButton, BImg} from 'bootstrap-vue'
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {INTERVENTIONS_CREATE, INTERVENTIONS_SHEET, INTERVENTIONS_EDIT} from "@/router/web/constants"
import {baseUrl} from '@/constants/app'

export default {
    name: "ListView",
    components: {
        BRow,
        BCol,
        BCard,
        BPagination,
        BLink,
        BCardBody,
        BButton,
        BImg,
    },
    data() {
        return {
            page: 1,
            pageLength: 10,
            totalRecords: 10,
            baseUrl,
            columns: [
                {key: 'id', label: 'Id', sortable: false},
                {key: 'urgent', label: 'Urgente', sortable: false},
                {key: 'equipment_id', label: 'Equipo', sortable: false},
                {key: 'tag_id', label: 'Tag', sortable: false},
                {key: 'actions', label: 'Acciones', sortable: false},
            ],
            searchTerm: '',
            showLoading: false,
        }
    },
    computed: {
        ...mapState('intervention', ['interventions']),
    },
    created() {
        this.getInterventions()
        this.getEquipment()
    },
    beforeDestroy() {
        this.$store.commit('SET_PAGE_TITLE', null)
    },
    methods: {
        can(permission) {
            return this.$store.getters.can(permission)
        },
        formatDate(date) {
            const dateFormated = new Date(date)

            return dateFormated.toLocaleDateString("es-ES")
        },
        getInterventions() {
            this.showLoading = true
            this.$store.dispatch('intervention/listByEquipment', {
                equipment_id: this.$route.params.id,
                stateOpened: this.$route.params.stateOpened,
                per_page: this.pageLength,
                page: this.page,
                relations: ['state'],
            }).then(response => {
                this.showLoading = false
                this.totalRecords = response.total_records
            })
                .catch(error => {
                    this.showLoading = false
                    console.log(error)
                })
        },
        getEquipment() {
            this.$store.dispatch('equipment/getEquipment', {
                id: this.$route.params.id,
                relations: ['client']
            }).then(response => {
                console.log(this.$route.params.stateOpened)
                this.$store.commit('SET_PAGE_TITLE', ' Listado intervenciones del equipo "' + response.serial_number + '"' + (this.$route.params.stateOpened==='true' ? ' abiertas.' : '.'))
            }).catch(error => {
                console.log(error)
            })
        },
        deleteIntervention(id) {
            Swal.fire({
                title: "Está seguro de borrar el intervencion?",
                text: "Una vez borrado no podrás recuperarlo!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Sí!",
                cancelButtonText: "Cancelar",
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('equipment/deleteIntervention', {id: id})
                        .then(response => {
                            this.showLoading = false
                            this.getInterventions()
                        })
                }
            })
        },
        changePage(page) {
            this.page = page
            this.getInterventions()
        },
        changeLength(length) {
            this.length = length
            this.getInterventions()
        },
        searchInterventions() {
            this.getInterventions()
        },
        goToCreate() {
            if (this.$route.params.id)
                this.$router.push({path: INTERVENTIONS_CREATE + `/${this.$route.params.id}`})
            else
                this.$router.push({path: INTERVENTIONS_CREATE})
        },
        toInterventionView(id) {
            this.$router.push({path: INTERVENTIONS_SHEET + `/${id}`})
        },
        toInterventionEdit(id) {
            this.$router.push({path: INTERVENTIONS_EDIT + `/${id}`})
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>